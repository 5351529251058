import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { AutoComplete } from 'primereact/components/autocomplete/AutoComplete';
import { compose, withProps, withHandlers } from 'recompose';
import { withState } from 'js/utils/with';

const Autocomplete =
    ({input: { value }, label, required, multiple, minLength, placeholder,
         delay, labelKey, itemTemplate, selectedItemTemplate,
         meta: { error }, showError, loadOptions, onSelect,
         setTouched, suggestions, selected, handleChange, suggest
    }) =>
  <div className={classNames('input-field input-autocomplete', { 'input-autocomplete-multiple': multiple === true })}>
    {label && <label>{label}</label>}{required && <span> *</span>}
    <br />
    <AutoComplete
      className={classNames({ 'input-error': showError })}
      onFocus={() => setTouched(false)}
      onBlur={() => setTouched(true)}
      minLength={minLength || 3}
      multiple={multiple || false}
      onChange={handleChange}
      onSelect={onSelect}
      value={value || selected}
      placeholder={placeholder || ''}
      delay={delay || 300}
      itemTemplate={itemTemplate || null}
      selectedItemTemplate={selectedItemTemplate || null}
      suggestions={suggestions}
      field={labelKey || null}
      loadOptions={loadOptions}
      completeMethod={suggest}
    />
    {showError && <div className="input-field-error"><FormattedMessage id={error} /></div>}
  </div>;

export default compose(
  withState('touched'),
  withState('selected'),
  withState('suggestions', []),
  withProps(({ meta: { error }, touched }) => ({ showError: touched && error })),
  withHandlers({
    handleChange: ({setSelected, setTouched, input, suggestions, onInputMatchingSuggestion}) => ({value}) => {
        setSelected(value);
        setTouched(true);
        input.onChange(value);
        if (onInputMatchingSuggestion && value && suggestions && suggestions.includes(value)) {
            onInputMatchingSuggestion(value);
        }
    },
    suggest: ({setSuggestions, loadOptions, options}) => ({ query }) => {
        if (loadOptions) {
            loadOptions(query)
                .then(r => setSuggestions(r.options))
        }
        else {
            setSuggestions((options || [])
                .filter(option =>
                    option && option.label && (option.label || '')
                        .toLowerCase()
                        .includes((query || '')
                            .toLowerCase())))
        }
    }
  }),
)(Autocomplete);