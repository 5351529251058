import axios from 'axios';
import { isArray, isEmpty } from 'lodash-es';

import {
  CUSTOMER_API_URL,
  STAFF_API_URL,
  CARS_API_URL,
  CARS_MSISDN_API_URL,
  INFLEETING_TELEMETER_SEARCH_API_URL
} from '../configuration/app.configuration';

function findById(API_URL, value, dataMapper) {
  let IDs = null;

  if (isArray(IDs) ? isEmpty(IDs) : !IDs) {
    return Promise.resolve();
  }


  if (isArray(value)) {
    IDs = value.map(entry => entry.id !== undefined ? entry.id : entry);

  } else {
    IDs = value.id !== undefined ? value.id : value;
  }

  const mapResponseData = response => dataMapper(response.data);

  if (isArray(IDs)) {
    return axios
      .all(
        IDs.map(ID => axios.get(`${API_URL}/${ID}`))
      )
      .then(responses => responses.map(mapResponseData));

  } else {
    return axios
      .get(`${API_URL}/${IDs}`)
      .then(mapResponseData);
  }
}

function findMatches(input, autocompleteUrl, dataMapper) {

  // Require a certain minimum of characters before autocompletion
  if (input && input.length >= 3) {

    const query = encodeURI(input);

    return axios.get(`${autocompleteUrl}${query}`)
      .then(({ data }) => {

        const options = (data.results || data.result || (Array.isArray(data)&&data) || []).map(dataMapper);
        return { options };
      })
      .catch(error => {
        console.error(error);

        // Return empty options in case of error
        return Promise.resolve({ options: [] });
      });

  } else {
    return Promise.resolve({ options: [] });
  }
}

// Customer typeahead response mapping
const mapResponseToCustomer = customer => ({
  value: customer.customerId,
  label: `${customer.firstName} ${customer.preferredName} ${customer.lastName}`,
  customerNumber: customer.customerNumber,
  emailAddress: customer.emailAddress,
  fullName: `${customer.firstName} ${customer.preferredName} ${customer.lastName}`
});

export function findCustomersMatches(input) {
  return findMatches(input, `${CUSTOMER_API_URL}?emailAddress=`, mapResponseToCustomer);
}

export function findCustomerById(ID) {
  return findById(CUSTOMER_API_URL, ID, mapResponseToCustomer);
}

// Staff typeahead response mapping
const mapResponseToStaff = staff => ({
  value: staff.id,
  label: `${staff.firstName} ${staff.lastName} (${staff.role})`,
  department: staff.department
});

export function findStaffMatches(input) {
  return findMatches(input, `${STAFF_API_URL}?fullName=`, mapResponseToStaff);
}

export function findStaffById(ID) {
  return findById(STAFF_API_URL, ID, mapResponseToStaff);
}

// Vehicle typeahead response mapping
const mapResponseToVehicle = vehicle => ({
  value: vehicle.vin,
  label: vehicle.plate
});

export function findVehicleMatches(input) {
  return findMatches(input, `${CARS_API_URL}?size=50000&plate=`, mapResponseToVehicle);
}

// MSISDN typeahead response mapping
const mapMSISDNToVehicle = msisdn => ({
  value: msisdn.toString(),
  label: msisdn.toString()
});
export function findVehicleMatchesMSISDN(input) {
  return findMatches(input, `${CARS_MSISDN_API_URL}`, mapMSISDNToVehicle);
}

export function findUnassociatedTelemeter(input) {
  return findMatches(input, `${INFLEETING_TELEMETER_SEARCH_API_URL}`, tId => tId);
}
